


























































































































































































































import Vue from 'vue';
import vuex from '../../store';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import MQTT from '@/modules/MQTT';
import { Global, Groups, User, Reports } from '@/store';
import PropUtils from '@/modules/PropUtils';
import MultipleSearchFilter from '@/components/MultipleSearchFilter.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    MultipleSearchFilter
  }
})
export default class CreateGroup extends Vue {
  @Prop() devices;
  @Prop() groupDevices;
  @Prop() createMode;
  @Prop() group;

  @Global.State('lang') lang;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Groups.Action('edit') edit;
  @Groups.Action('create') create;
  @Groups.State('list') groups;
  @User.State('project') project;
  @Reports.Getter('commissioned_map') commissioned_map;

  groupName: string = '';
  groupDescription: string = '';
  mqtt = MQTT.instance;
  search = '';
  allChecked = false;
  isChecked = {};
  nameError: any = [];
  saveError: string = '';
  loading = true;
  filterDialog = false;
  deviceTypes = ['tondo'];
  filter_fields = [];
  checkedDevices = null;
  rowsPerPageItems = [5, 10, 20];
  pagination = { rowsPerPage: 5 };
  PropUtils = PropUtils;

  mounted() {
    this.loading = true;
    this.filter_fields = [];
    if (!this.createMode) {
      this.groupName = this.group.name;
      this.groupDescription = this.group.description;
    }
    this.setIsChecked();
    this.loading = false;
  }

  get title() {
    return this.createMode ? 'Create Fixtures Group' : 'Edit Fixtures Group';
  }

  get buttonText() {
    return this.createMode ? 'Create' : 'Save';
  }

  @Watch('filter_fields')
  get devicesList() {
    const prevDevicesIds = [];
    for (const key in this.isChecked) {
      if (this.isChecked[key]) {
        prevDevicesIds.push(key);
      }
    }
    const prevDevices = [];
    for (const i of prevDevicesIds) {
      prevDevices.push(this.devices.find((dev) => dev.id === i));
    }

    let currentDevices = this.filter_fields.length
      ? this.getDevicesByFilter()
      : this.devices;
    currentDevices = currentDevices.filter(
      (dev) => prevDevicesIds.indexOf(dev.id) < 0
    );
    currentDevices = currentDevices.concat(prevDevices);

    const devicesBySearch = currentDevices.filter((v) =>
      v.name.toLowerCase().includes(this.search.toLowerCase())
    );
    this.allChecked = devicesBySearch.length === this.groupDevices.size;
    if (this.allChecked) {
      for (const index of currentDevices) {
        this.isChecked[index.id] = true;
      }
      this.checkedDevices = currentDevices;
    }
    return devicesBySearch.sort((device1, device2) =>
      device1.name.localeCompare(device2.name)
    );
  }

  getDevicesByFilter() {
    let filter_devices = cloneDeep(this.devices);
    this.filter_fields.forEach((field) => {
      filter_devices = filter_devices.filter((device) => {
        const field_value = this.commissioned_map.get(device.id)['meta.device'][field.name];
        if (!field_value) return false;
        return field.selected.includes(field_value.toString());
      });
    });
    return filter_devices;
  }

  setFilterDialogOpen() {
    this.filterDialog = !this.filterDialog;
  }

  @Watch('groupDevices')
  async setIsChecked() {
    this.isChecked = {};
    this.checkedDevices = [];

    this.devices.forEach(
      (device) => (this.isChecked[device.id] = this.groupDevices.has(device.id))
    );

    const tempCheckedDevices = [];
    for (const device_id in this.isChecked) {
      if (this.isChecked[device_id] === true) {
        if (this.devicesList.find((device) => device.id === device_id)) {
          const deviceName = this.devicesList.find((device) => device.id === device_id)['name'];
          tempCheckedDevices.push({
            id: device_id,
            name: deviceName,
            cabinet: PropUtils.getMetaDeviceField(this.commissioned_map.get(device_id)['meta.device'], 'cabinet_id').split(': ')[1],
            circuit: PropUtils.getMetaDeviceField(this.commissioned_map.get(device_id)['meta.device'], 'circuit_number').split(': ')[1],
            pole: PropUtils.getMetaDeviceField(this.commissioned_map.get(device_id)['meta.device'], 'pole_number').split(': ')[1]
          });
        }
      }
    }
    this.checkedDevices = tempCheckedDevices.sort((a, b) => a.name > b.name ? 1 : -1);
  }

  setDevicesList(selected_fields) {
    this.filter_fields = cloneDeep(selected_fields);
    const devices = this.getDevicesByFilter();
    this.$emit('selected', devices, true);

    // this.$emit('selectAll', []);
  }

  sendDeviceCommand() {
    const mqtt_publish_data = {
      message_type: 'cmd',
      format: 'minimal',
      stream_value: 'AT+reload',
      options: {
        retain: false,
        qos: 1
      }
    };

    [...this.groupDevices.values()].forEach((device) => {
      let mqtt_version = 3;
      if (this.mqtt_version_by_class.has(device.class_name)) {
        mqtt_version = this.mqtt_version_by_class.get(device.class_name).mqtt_version;
      }
      this.mqtt.publishToSingleDevice({
        ...mqtt_publish_data,
        version_num: mqtt_version,
        device_id: device.id,
        stream_id: mqtt_version === 3 ? `${device.id}.command` : 'command'
      });
    });
  }

  getFilterList() {
    let filter_list_string = '';
    this.filter_fields.forEach((field, index) => {
      filter_list_string = filter_list_string.concat(
        `${field.name}: ${field.selected.join(', ')}`
      );
      if (index < this.filter_fields.length - 1)
        filter_list_string = filter_list_string.concat(', ');
    });

    return filter_list_string;
  }

  async handleGroupAction() {
    if (!this.groupName) {
      return;
    }
    if (!this.groupDevices.size) {
      vuex.dispatch('Global/throwNotify', {
        type: 'error',
        title: `${this.$t('Error')}!`,
        text: this.$t(`You must choose at least one device`)
      });
      return;
    }
    if (this.groups.length) {
      if (
        (this.createMode &&
          this.groups.some(
            (group) =>
              group.name.trim().toLowerCase() ===
              this.groupName.trim().toLowerCase()
          )) ||
        (!this.createMode &&
          this.groups.some(
            (group) =>
              group.id !== this.group.id &&
              group.name.trim().toLowerCase() ===
                this.groupName.trim().toLowerCase()
          ))
      ) {
        this.nameError = [`${this.$t('This name already exists')}`];
        return;
      }
    }
    if (!this.groupName.match(/^[a-zA-Z1-9_-]+$/g)) {
      this.nameError = [`${this.$t('Group name can contain only the characters')} a-z A-Z 1-9 _- ${this.$t('without spaces')}`];
      return;
    }
    this.createMode
      ? await this.createGroup()
      : await this.saveGroup();
    this.sendDeviceCommand();
    this.$router.push('/groups');
  }

  async saveGroup() {
    const data = {
      data: {
        project: this.project.id,
        name: this.groupName,
        description: this.groupDescription,
        devices: [...this.groupDevices.keys()],
        stream_list: 'power'
      },
      project_id: this.project.id,
      group_id: this.group.id
    };
    await this.edit(data);
  }

  async createGroup() {
    const data = {
      data: {
        project: this.project.id,
        name: this.groupName || '',
        description: this.groupDescription || '',
        devices: [...this.groupDevices.keys()] || [],
        stream_list: 'power'
      },
      project_id: this.project.id,
    };
    await this.create(data);
  }

  updateDevices(device) {
    this.$emit('selected', [device]);
    this.allChecked = this.devices.length === this.groupDevices.size;
  }

  selectAll() {
    if (this.allChecked) {
      const currentDevices = this.filter_fields.length
        ? this.getDevicesByFilter()
        : this.devices;
      const selected = currentDevices.filter((v) =>
        v.name.toLowerCase().includes(this.search.toLowerCase())
      );
      this.$emit('selectAll', selected);
    } else this.$emit('selectAll', []);
  }

  clearData() {
    this.filter_fields = [];
    this.search = '';
    this.checkedDevices = [];
    this.$emit('clear');
  }
}
